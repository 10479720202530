import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import Img5 from '../images/new/blog/post/image-5.jpeg'
import Img6 from '../images/new/blog/post/image-6.jpeg'
import Img7 from '../images/new/blog/post/image-7.jpeg'

import { 
  section,
  container,
  content,
  column,
  columns
} from '../components/styles/Base.styles'


const BlogItem = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="A leitura e o despertar para o mundo da imaginação" />
    <NavBar />
    {/* <div css={HeaderBanner}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div> */}
    <div css={[section, content]} style={{ paddingBottom: 0, marginTop: 112 }}>
      <div css={container}>
        <h1>A leitura e o despertar para o mundo da imaginação</h1>
        <h3 style={{ color: '#000000', marginBottom: 36, maxWidth: '100%' }}>Um programa de leitura com novidades é capaz de transformar a vivência na escola em um lugar de estímulo à imaginação, gosto pelas histórias e envolvimento com o mundo literário.</h3>
        <p>Em 2022, as crianças fizeram uma viagem com a fábula de Antoine de Saint-Exupéry, “O Pequeno Príncipe”, que tornou os seus dias memoráveis. Além deste ser o tema norteador, muitas atividades foram realizadas, tomando por base as características dos personagens principais que se desdobraram em: “The fox’s game”, um jogo simples de cartas para fortalecer a amizade em inglês; “nossa horta”, que foi cultivada pelas turmas de quatro anos, onde elas puderam acompanhar o plantio de milho, cenoura, beterraba e alface, tudo inspirado nas lições da raposa; e muitas releituras, exposições, música e muito movimento.</p>
        <p>Tivemos, também, vários circuitos montados no Jogo da Ação, uma gincana realizada no meio do ano, trazendo o deserto do Saara para a realidade escolar, de maneira lúdica e prazerosa, onde os estudantes praticaram várias dinâmicas em equipe, receberam medalhas e, com o apoio familiar, as turmas vencedoras ganharam um lindo troféu.</p>
        <p>As atividades não ficaram restritas apenas ao Exupéry, muitos livros fizeram parte da jornada escolar na Ciranda de Livros, com vários autores, saindo toda sexta e retornando na segunda subsequente. Na Educação Infantil, os livros eram levados pelas crianças dentro da sacola literária e no Fundamental, na mochila. Foi um verdadeiro “Troca troca da alegria”.</p> 
        <p>Para ficar melhor ainda, veio a Amarelinha, uma fase do projeto que proporciona aflorar ainda mais a imaginação dos estudantes que tiveram a oportunidade de sonhar, imaginar o seu livro e realizar esse intento, um por turma na Educação Infantil e um por estudante no Fundamental. Essa experiência, possibilitou uma vivência incrível de autoria e ilustração de um livro, pois “na escola a gente constrói muitas histórias”. E o detalhe é que, não só os estudantes, mas toda a equipe pedagógica entrou no clima, em todas as fases, e fizeram da Kingdom um verdadeiro espaço de arte e criatividade, que culminou em memórias afetivas.</p>
        <p>E não para por aí, pois em 2023 teremos mais. Qual será o “tema da vez?”</p>
        <div css={columns}>
          <div css={column}>
            <img src={Img5} alt='' style={{ width: '90%' }} />
          </div>
          <div css={column}>
            <img src={Img6} alt='' style={{ width: '90%' }} />
          </div>
          <div css={column}>
            <img src={Img7} alt='' style={{ width: '90%' }} />
          </div>
        </div>
				<div style={{ paddingBottom: 112 }} />
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default BlogItem
